<script>
import {authMethods} from "@/state/helpers";
import {required, email} from "vuelidate/lib/validators";
import HttpRequest from "@/http/HttpRequest";

import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);


let httpRequest = new HttpRequest()

export default {
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false
    };
  },
  validations: {
    email: {required, email}
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {

        let _this = this
        // Init loader
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots"

        });

        // Init sweet alert2
        let swal = this.$swal

        httpRequest.resendUserVerifyMail(this.email).then(function (response) {
          loader.hide()
          if (response.success) {
            _this.$router.push({path: `/verify-email?id=${_this.email}`});
          } else {
            swal(response.message);
          }
        })
      }
    }
  }
};
</script>

<template>
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-lg-6 col-xl-4 offset-lg-3 offset-xl-4">
        <div
            class="authentication-page-content p-4 d-flex align-items-center flex-column justify-content-center min-vh-100">
          <div class="text-center">
            <div>
              <a href="/" class="WorkAny">
                <img src="@/assets/images/workany-v-logo.png" height="130" width="180" alt="WorkAny"/>
              </a>
            </div>

            <h4 class="font-size-22 mt-4">Confirm your email</h4>
            <h6 class="font-size-14 mt-4">This confirmation link has already been used or the link expired.</h6>

          </div>

          <div class="p-2 mt-3 mt-md-4 w-100" style="max-width: 440px">
            <b-alert
                v-model="isResetError"
                class="mb-4"
                variant="danger"
                dismissible
            >{{ error }}
            </b-alert>

            <form class="form-horizontal" @submit.prevent="tryToReset">
              <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                <i class="ri-mail-line auti-custom-input-icon"></i>
                <label for="useremail">Email</label>
                <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    id="useremail"
                    placeholder="Enter your email"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                  <span v-if="!$v.email.required">Email is required.</span>
                  <span v-if="!$v.email.email">Please enter valid email.</span>
                </div>
              </div>

              <div class="mt-3 mt-md-4 text-center">
                <button class="btn btn-primary w-md waves-effect waves-light customs-bg-color" type="submit">RESEND CONFIRMATION</button>
              </div>

            </form>
          </div>
          <div class="mt-2 text-center">
            <router-link to="/login">Back to sign in</router-link>
          </div>
          <div class="mt-3 mt-md-5 text-center position-absolute fixed-bottom ">
            <p>© 2023 WORKANY. Crafted by <a href="https://w3engineers.com/" class="text-primary" target="_blank">W3
              Engineers Ltd</a>.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customs-bg-color{
  background-color: #4B1FE5 !important;
}
</style>
